<template>
    <div class="myClass" style="border: 0px solid #cccccc;">
        <div>
            <div v-for="(item,index) in dataList" :key="index">
                <div class="myClass_box" style="margin-bottom:15px;border:1px solid #cccccc;border-radius:6px;">
                    <div class="myClass_box_left">
                        <div class="myClass_box_left_img" style="width:100px;">
                            <img :src="item.teachTeacherHeadPath" alt />
                        </div>
                        <div class="myClass_box_left_content">
                            <p>{{ item.name }}</p>
                            <p>
                                <span>时间：{{ item.playDate }} {{ item.plaTime }}</span>
                                <span v-if="item.completeState === 1" style="margin-left:15px;color:red">学习已完成</span>
                            </p>
                            <!--<p>
                                <el-progress
                                    :percentage="item.studyCourseCount>0? Number(item.studyCourseCount) / Number(item.classHourCount1) * 100:0"
                                    :show-text="false"
                                    :stroke-width="10"
                                ></el-progress>
                                <span>已学习：{{ item.studyCourseCount }}/{{ item.classHourCount1 }}课时</span>
                            </p>-->
                        </div>
                    </div>
                    <button v-if="item.state == 1" class="myClass_box_right" @click="jinruzhibo(index, item)" style="cursor:pointer;">进入直播</button>
                    <span v-if="item.state == 0">等待开播</span>
                    <!-- <button v-if="item.state == 2" class="myClass_box_right" @click="chakanhuifang(item)" style="cursor:pointer;">回放1</button> -->
                    <button v-if="item.state == 2" class="myClass_box_right" @click="chakanhuifangNew(item)" style="cursor:pointer;">回放</button>
                    <!-- 0待开播：1：进入；2：回访 -->
                   
                </div>
            </div>
        </div>
        <div class="paging" v-if="dataList.length">
            <el-pagination background layout="prev, pager, next" :total="count" @current-change="handleCurrentChange"></el-pagination>
        </div>
        <div class="noMore" v-else>暂无数据</div>
    </div>
</template>

<script>
import { has_buy, course_list, zhiboliebiao } from "../../api/myself"
export default {
    name: 'liveItem',
    data() {
        return {
            dataList: [],
            courseObj: {},
            count: 0,
            page:0
        }
    },
    mounted() {
        this.courseObj = JSON.parse(this.$route.query.item)
        this.zhiboliebiao();

    },
    methods: {
        // 接口
        zhiboliebiao() {
            let obj = {
                courseId: this.courseObj.id,
                page: this.page
            }
            zhiboliebiao(obj).then(res => {
                console.log(res);
                if (res.data.code == 0) {
                    this.count = res.data.count;
                    this.dataList = res.data.data;
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        },
        jinruzhibo(index, item) {
            console.log(item)
            // var url='https://live.polyv.cn/watch/'+item.channelId
            // window.open(url, '_blank');
            
            let routeUrl = this.$router.resolve({
                path: '/videoPlayRoom',
                query: {
                    channelId: item.channelId,
                    title: item.name,
                    id: item.id,
                    liveType: 2,
                    courseId: item.courseId,
                    coursePackageId: item.coursePackageId,
                    state:item.state,
                    title:item.name

                }
            });
            window.open(routeUrl.href, '_blank');
        },
        chakanhuifang(item) {
            let routeUrl = this.$router.resolve({
                path: '/videoPlay',
                query: {
                    channelId: item.channelId,
                    id:item.id,
                    courseId: item.courseId,
                    coursePackageId : item.coursePackageId
                }
            });
            window.open(routeUrl.href, '_blank');
        },
        chakanhuifangNew(item) {
            let routeUrl = this.$router.resolve({
                path: '/videoPlayRoom',
                query: {
                    channelId: item.channelId,
                    id:item.id,
                    courseId: item.courseId,
                    liveType: 2,
                    coursePackageId : item.coursePackageId,
                    state:item.state
                }
            });
            window.open(routeUrl.href, '_blank');
        },
        goLiveItemPage(item) {
            let routeUrl = this.$router.resolve({
                path: '/videoRoom',
                query: {
                    videoObj: JSON.stringify(videoObj),
                    clickItem: JSON.stringify(clickItemObj),
                    classDetail: JSON.stringify(classDetailObj),
                    coursePackageId: item.coursePackageId
                }
            })
            window.open(routeUrl.href, '_blank');
        },
        handleCurrentChange(e) {
            this.page = e-1;
            this.zhiboliebiao();
        }

    }
}
</script>

<style>
.noMore {
    text-align: center;
    width: 100%;
    color: #999;
    font-size: 14px;
    margin: 40px 0;
}
.paging {
    margin-top: 40px;
}
</style>